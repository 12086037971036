export const COUNTRIES = [
  { name: "Belgium", code: "BE", disabled: false },
  { name: "Brussels", code: "BR", disabled: true },
  { name: "Flanders", code: "FL", disabled: true },
  { name: "Wallonia", code: "WA", disabled: true }
];

export const VALUE_CONVERSION_TABLE = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "A",
  "B",
  "C",
  "D",
  "E"
];
