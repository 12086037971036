import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import "./LeversBtn.css";

// Setup React.MEMO
const areEqual = (prevProps, nextProps) => {
  return prevProps === nextProps;
};

const LeversBtn = React.memo(props => {
  const { initialValue, isActive, handleLeversSelected, value, range} = props;
  const [leversValue, setLeversValue] = useState(value);

  const handleClick = initialValue => {
    let newValue = 1;
    if (initialValue > 1) {
      if (isActive)
        newValue =
          leversValue < initialValue - 0.8
            ? initialValue
            : (leversValue - 0.1).toFixed(1);
      else {
        newValue = initialValue;
      }
    }
    handleLeversSelected(newValue);
  };

  useEffect(() => {
    setLeversValue(value);
  }, [value]);

  return (
    <span
      data-toggle="tooltip"
      data-placement="top"
      title={props.definition ? props.definition : null}
      className={
        isActive ? "levers__selected levers__btn ripple" : "levers__btn ripple"
      }
      onClick={() => handleClick(initialValue)}
    >
      {isActive ? leversValue.toString().replace(/^./gi,range[Math.floor(leversValue)-1]) : initialValue.toString().replace(/^./gi,range[initialValue-1])}
    </span>
  );
}, areEqual);

const mapStateToProps = state => {
  return {
    levers: state.stateLevers
  };
};

export default connect(mapStateToProps)(LeversBtn);
