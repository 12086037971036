import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from "./Components/Main";
import Header from "./Components/Header/Header";
import GeneralCondition from "./Components/GeneralCondition"
//import Footer from "./Components/Footer/Footer";

const App = () => {
  return (
    <>
      <Header />
      <Router>
        <Route exact path="/" component={Main} />
        <Route exact path="/condition-of-use" component={GeneralCondition} />
      </Router>
      {/*<Footer />*/}
    </>
  );
};

export default App;
