import React, { useState, useEffect } from "react";
import LeversBtn from "./LeversBtn/LeversBtn";
// import LeversBtnLetter from "./LeversBtn/LeverBtnLetter";
import { connect } from "react-redux";
import "./LeversSelector.css";
import ModalLevers from "../Modal/ModalLevers";

const areEqual = (prevProps, nextProps) => {
  return prevProps === nextProps;
};

const LeversSelector = React.memo(props => {
  const [showModal, setShowModal] = useState(false);

  //Hide or show the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [leversValue, setLeversValue] = useState(null);
  const [show, setShow] = useState(false);
  const handleLeversSelected = leversValue => {
    setLeversValue(leversValue);
    if (props.type === "title") {
      props.setThisLevers(props.id, +leversValue * 10);
    } else {
      props.setGroupLevers(props.type, props.title, +leversValue * 10);
    }
    props.reload();
  };
  let { type, title } = props;

  // Set Levers value state on props change
  useEffect(() => {
    setLeversValue(props.value / 10);
  }, [props]);
  const dropDown = () => {
    setShow(!show);
  };

  // Check if range is undefined and replace by default number array
  let range = props.range === undefined ? ["1", "2", "3", "4"] : props.range;

  // Use letters if range is alphabetical else use numbers
  let LeversBtnList = range.map((e, i) => {
    const initialValue = i + 1;
    // remove the following section to allow Letter levers to have decimal values
    // if (isNaN(range[0])) { 
    //   return (
    //     <LeversBtnLetter
    //       key={initialValue}
    //       initialValue={initialValue}
    //       letter={e}
    //       isActive={leversValue === initialValue}
    //       id={props.id}
    //       value={initialValue}
    //       handleLeversSelected={handleLeversSelected}
    //       definition={
    //         props.definition ? props.definition[`l${initialValue}`] : null
    //       }
    //     />
    //   );
    // } else {
      return initialValue === 1 ? (
        <LeversBtn
          key={initialValue}
          initialValue={initialValue}
          range= {range}
          isActive={leversValue === initialValue}
          id={props.id}
          value={initialValue}
          handleLeversSelected={handleLeversSelected}
          definition={
            props.definition ? props.definition[`l${initialValue}`] : null
          }
        />
      ) : (
        <LeversBtn
          key={initialValue}
          initialValue={initialValue}
          range = {range}
          isActive={
            leversValue > initialValue - 1 && leversValue <= initialValue
          }
          id={props.id}
          value={
            leversValue > initialValue - 1 && leversValue <= initialValue
              ? leversValue
              : initialValue
          }
          handleLeversSelected={handleLeversSelected}
          definition={
            props.definition ? props.definition[`l${initialValue}`] : null
          }
        />
      );
    // }
  });

  return (
    <React.Fragment>
      <ModalLevers
        definition={props.definition ? props.definition.lever : null}
        sources={props.definition ? props.definition.sources : null}
        assumptions={props.definition ? props.definition.assumptions : null}
        l1={props.definition ? props.definition.l1 : null}
        l2={props.definition ? props.definition.l2 : null}
        l3={props.definition ? props.definition.l3 : null}
        l4={props.definition ? props.definition.l4 : null}
        l1_data={props.definition ? props.definition.l1_data : null}
        l2_data={props.definition ? props.definition.l2_data : null}
        l3_data={props.definition ? props.definition.l3_data : null}
        l4_data={props.definition ? props.definition.l4_data : null}
        figure_caption={
          props.definition ? props.definition.figure_caption : null
        }
        unit={props.definition ? props.definition.unit : null}
        name={props.title}
        id={props.id}
        show={showModal}
        modalClosed={toggleModal}
      />
      {
        <div className={type === "title" ? (props.display !== "NO" ? "pl-2" : "") : ""}>
          <div className={props.display !== "NO" ?  "lever d-flex mb-5 " + type : ""}>
            <div
              className={props.display !== "NO" ? "pointer" : "hide"}
              data-toggle="tooltip"
              data-placement="top"
              title={props.definition ? props.definition.lever : null}
              onClick={props.children ? dropDown : toggleModal}
              
            >
              {props.children ? (
                show ? (
                  <i className="fas fa-chevron-down mr"></i>
                ) : (
                  <i className="fas fa-chevron-right mr"></i>
                )
              ) : (
                ""
              )}
              {title}
            </div>

            <div className={props.display !== "NO" ? "all-selectors" : "hide"}>{LeversBtnList}</div>
          </div>

          <div className={show ? "" : "hide"}>{props.children}</div>
        </div>
      }
    </React.Fragment>
  );
}, areEqual);

const mapStateToProps = state => {
  return {
    levers: state.stateLevers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setThisLevers: (id, value) =>
      dispatch({ type: "SET_THIS_LEVERS", payload: { id: id, value: value } }),
    setGroupLevers: (group, title, value) =>
      dispatch({
        type: "SET_GROUP_LEVERS",
        payload: { group: group, title: title, value: value }
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeversSelector);
