import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./MapDisplay.css";

import graphMetricsCall from "../../utils/graphMetricsCall";
import mapDataHelper from "../../helpers/mapDataHelper";
import Loading from "../Loading/Loading";
import Modal from "../Modal/Modal";
import Collapsible from 'react-collapsible';


const areEqual = (prevProps, nextProps) => {
  return prevProps === nextProps;
};

const MapDisplay = React.memo(props => {
  const [mapOneMetrics, setMapOneMetrics] = useState({
    opts: null,
    series: null
  });
  const [mapTwoMetrics, setMapTwoMetrics] = useState({
    opts: null,
    series: null
  });
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("default");
  const [kpiList, setKpiList] = useState({
    value: [],
    title: []
  });
  //const [localResults, setLocalResults] = useState([]);

  // Declare call function
  const fetchData = async (fetchUrl, metricsToCall) => {
    const leversValue = props.levers.map(e => {
      return e.value / 10;
    });

    if (props.levers.length !== 0) {
      // THIS CALL IS USED TO FETCH ALL METRICS DATA FOR THE MAPS

      let result = await graphMetricsCall(fetchUrl, metricsToCall, leversValue);
      //console.log(result);
      //UNDER DEV => replace result by setLocalResults to keep the result in the memory when changing the country
      //setLocalResults(result);
      //console.log(localResults);
      // * IF RESPONSE IS EMPTY
      if (result.length === 0) {
        if (fetchUrl === "https://becalc.netzero2050.be/model/v1.0/results") {
          //FIXME: if we ask the server on the port 5000 and the answer is an empty array, it means that the server is busy
          setModalMessage("busy");
        } else {
          setModalMessage("default");
        }
        setShowModal(true);
        return;
      } else {
        setShowModal(false);
      }

      // * FOR THE FIRST MAP
      if (result.outputs) {
        var one = [];

        result.outputs.forEach(metric => {
          props.callInfos[0].metrics.forEach(id => {
            if (metric.id === id) {
              one.push(metric);
            }
          });
        });
        const mapOneData = {
          ...props.callInfos[0],
          metrics: one
        };
        setMapOneMetrics(mapDataHelper.getData(mapOneData, props.country));
      }
      // * FOR THE SECOND MAP
      if (props.callInfos[1]) {
        var two = [];
        result.outputs.forEach(metric => {
          props.callInfos[1].metrics.forEach(id => {
            if (metric.id === id) {
              two.push(metric);
            }
          });
        });
        const mapTwoData = {
          ...props.callInfos[1],
          metrics: two
        };
        setMapTwoMetrics(mapDataHelper.getData(mapTwoData, props.country));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }

      if (result.kpi) {
        const values = [];
        const titles = [];
        for (const item of result.kpi) {
          titles.push(item['title']);
          values.push((item.data[props.country.value]*100).toFixed(2)+' %');
        }
        setKpiList({value: values,
                  title: titles
      })
      }
    }
  };
  //? Check when the callInfo changes (click on menu) and call the API accordingly
  useEffect(() => {
    setShowModal(false);
    if (props.callInfos) {
      const metricsToCall = [];
      props.callInfos.forEach(graph => {
        graph.metrics.forEach(metric => {
          metricsToCall.push({
            id: metric,
            allCountries: true
          });
        });
      });
      setTimeout(() => {
        fetchData("https://becalc.netzero2050.be/api/v1.0/results", metricsToCall);
      }, 200);
    }
  }, [props.callInfos]); // eslint-disable-line

  //? Check when the country changes (click on menu) and call the API accordingly
  //FIXME: everything is already in memory... should not be a call to the server !!
  useEffect(() => {
    if (props.callInfos) {
      const metricsToCall = [];
      props.callInfos.forEach(graph => {
        graph.metrics.forEach(metric => {
          metricsToCall.push({
            id: metric,
            allCountries: true
          });
        });
      });
      setTimeout(() => {
        fetchData("https://becalc.netzero2050.be/api/v1.0/results", metricsToCall);
      }, 200);
    }
  }, [props.country]); // eslint-disable-line

  //?Call the Api if calc button is pressed or lever change
  useEffect(() => {
    setShowModal(false);
    //setIsLoading(true);
    if (props.callInfos) {
      const metricsToCall = [];
      props.callInfos.forEach(graph => {
        graph.metrics.forEach(metric => {
          metricsToCall.push({
            id: metric,
            allCountries: true
          });
        });
      });
      fetchData("https://becalc.netzero2050.be/api/v1.0/results", metricsToCall);
    }
  }, [props.isUpdating]); // eslint-disable-line

  //Hide or show the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  //calculate new if modal is accepted
  const calcNew = () => {
    setShowModal(false);
    setIsLoading(true);
    const metricsToCall = [];
    props.callInfos.forEach(graph => {
      graph.metrics.forEach(metric => {
        metricsToCall.push({
          id: metric,
          allCountries: true
        });
      });
    });
    fetchData("https://becalc.netzero2050.be/model/v1.0/results", metricsToCall);
  };

  //* –––––––––––––––––––––––
  //*
  //* rendering
  //* {mapOneMetrics.type} and {mapTwoMetrics.type} to allow mixed graphs
  //* –––––––––––––––––––––––

  let firstMap = null;
  if (mapOneMetrics.opts && props.callInfos !== undefined) {
    let height_temp = "300";
    if (props.callInfos[1] === undefined) {
      // there is only one graph => bigger
      height_temp = "650";
    }
    firstMap = (
      <div>
        <h4>{props.callInfos[0].Name}</h4>
        <Chart
          options={mapOneMetrics.opts}
          series={mapOneMetrics.series}
          type="line"
          height={height_temp}
        />
        <div className="graph-note">
          <p>{props.callInfos[0].Description}</p>
        </div>
      </div>
    );
  }
  let secondMap = null;
  if (mapTwoMetrics.opts && props.callInfos !== undefined) {
    if (props.callInfos[1] === undefined) {
      // there is only one graph (top)
      secondMap = null;
    } else {
      secondMap = (
        <div>
          <h4>{props.callInfos[1].Name}</h4>
          <Chart
            options={mapTwoMetrics.opts}
            series={mapTwoMetrics.series}
            type="line"
            height="300"
          />
          <div className="graph-note">
            <p>{props.callInfos[1].Description}</p>
          </div>
        </div>
      );
    }
  }

  let kpiMap = null;
  if (kpiList !== undefined) {
    kpiMap = (
      <div>
        <b>{kpiList.title[2]}</b> : {kpiList.value[2]} &nbsp;&nbsp; <b>{kpiList.title[1]}</b> : {kpiList.value[1]} &nbsp;&nbsp; <b>{kpiList.title[0]}</b> : {kpiList.value[0]}
      </div>
    );
  }

  return (
    <React.Fragment>
      <Modal
        message={modalMessage}
        show={showModal}
        modalClosed={toggleModal}
        calcNew={() => calcNew()}
      />
      {isLoading ? (
        <div className="map-display">
          {/*<Modal
            show={showModal}
            modalClosed={() => toggleModal()}
            calcNew={() => calcNew()}
          />*/}
          <div className="mt-2">
            <Loading />
            <p
              style={{
                textAlign: "center",
                marginTop: "30px"
              }}
            >
              Calculating results...
            </p>
          </div>
        </div>
      ) : (
        <> 
        <Collapsible trigger="KPIs">
          {kpiMap}
        </Collapsible>
        <div className="map-display">
          {firstMap}
          {secondMap}
        </div>
        </>
      )}
    </React.Fragment>
  );
}, areEqual);

export default MapDisplay;
