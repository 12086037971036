import React, { useState, useEffect } from "react";
import Select from "react-select";
import { COUNTRIES } from "../../utils/constants";

import graphDetailsCall from "../../utils/graphDetailsCall";

import "./MapControl.css";
import MapDisplay from "./MapDisplay";

const MapControl = props => {
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedSubtitle, setSelectedSubtitle] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    value: COUNTRIES[0].code,
    label: COUNTRIES[0].name
  });

  // THIS STATE IS USE TO STORE ALL DATA ABOUT TITLE SUBTITLE AND METRICS DATA
  const [dataDisplay, setDataDisplay] = useState({});

  // Call for the metrics and menu informations
  useEffect(() => {
    const fetchMenuAndMetricsData = async () => {
      if (dataDisplay) {
        const data = await graphDetailsCall();
        await setDataDisplay(data);
      }
    };
    fetchMenuAndMetricsData();
  }, []); // eslint-disable-line
  // [] => Excecute effect only once (during mounting the component)

  // CALL API
  useEffect(() => {
    let fetchedFirstTitle = Object.keys(dataDisplay)[0];
    setSelectedTitle(Object.keys(dataDisplay)[0]);
    setSelectedSubtitle(
      Object.keys({
        ...dataDisplay[fetchedFirstTitle]
      })[0]
    );
  }, [dataDisplay]); //Execute effect only if dataDisplay has changed (list of metrics + title/subtitles)

  // useEffect(() => {
  //   const fetchMetrics = async () => {
  //     const title = selectedTitle;
  //     const subtitle = selectedSubtitle;
  //     const leversValue = props.levers.map(e => {
  //       return e.value / 10;
  //     });
  //     if (props.levers.length !== 0) {
  //       const metrics = dataDisplay[title][subtitle][0].metrics.map(e => ({
  //         id: e,
  //         allCountries: true
  //       }));
  //       // THIS CALL IS USED TO FETCH ALL DATA FROM SELECTOR
  //       let result = await graphMetricsCall(
  //         "https://becalc.netzero2050.be/api/v1.0/results",
  //         metrics,
  //         leversValue
  //       );
  //       if (result.outputs) {
  //         setMetricsData(result);
  //       }
  //     }
  //   };
  //   fetchMetrics();
  // }, [selectedSubtitle]);

  const selectTitleHandler = async title => {
    await setSelectedTitle(title);
    setSelectedSubtitle(Object.keys(dataDisplay[title])[0]);
  };

  const handleSubtitleChange = e => {
    setSelectedSubtitle(e.value);
  };

  const handleCountryChange = e => {
    setSelectedCountry(e);
  };

  //* –––––––––––––––––––––––
  //*
  //* rendering
  //*
  //* –––––––––––––––––––––––

  const controlTitles = Object.keys(dataDisplay).map((title, i) => (
    <li key={i} onClick={() => selectTitleHandler(title)}>
      <div className={title === selectedTitle ? "inner selected" : "inner"}>
        {title}
      </div>
    </li>
  ));

  let controlSubTitles = {};

  if (Object.keys(dataDisplay).length > 0) {
    let a = Object.keys({
      ...dataDisplay[selectedTitle]
    });
    controlSubTitles = a.map(subtitle => {
      return {
        value: subtitle,
        label: subtitle
      };
    });
  }

  const countriesOptions = COUNTRIES.map(country => ({
    label: country.name,
    value: country.code,
    isDisabled: country.disabled
  }));
  return (
    <div>
      <div className="map-control">
        <div>
          <ul className="map-control-list">{controlTitles}</ul>
        </div>
        <div className="selectors">
          <Select
            isSearchable = {false}
            className="zmax"
            onChange={handleSubtitleChange}
            value={{
              value: selectedSubtitle,
              label: selectedSubtitle
            }}
            options={controlSubTitles}
          />
          <Select
            isSearchable = {false}
            className="zmax"
            value={selectedCountry}
            options={countriesOptions}
            onChange={handleCountryChange}
          />
        </div>
      </div>
      <div className="maps-wrapper">
        {dataDisplay[selectedTitle] &&
        dataDisplay[selectedTitle][selectedSubtitle] ? (
          <MapDisplay
            callInfos={dataDisplay[selectedTitle][selectedSubtitle]}
            levers={props.levers}
            country={selectedCountry}
            isUpdating={props.isUpdating}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MapControl;
