import React from "react";

import "./Loading.css";

const Loading = props => {
  return (
    <div className="loader">
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Loading;
