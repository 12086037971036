import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as queryString from "query-string";
import Levers from "./Levers/Levers";
import { VALUE_CONVERSION_TABLE } from "../utils/constants";
import "./Main.css";
import MapControl from "./Display/MapControl";

const Main = props => {
  //const [mainLevers, setMainLevers] = useState(props.levers);
  const [isUpdating, setIsUpdating] = useState(true);
  const [pathways, setPathways] = useState({});
  //Get all details for levers
  const getLeversFromApi = async () => {
    const response = await fetch(
      "https://becalc.netzero2050.be/api/v1.0/lever_details",
      {
        method: "GET"
      }
    );
    let fetchedLevers = await response.json();

    //Take URL Informations
    //const str = `?levers=`;
    let urlData = queryString.parse(props.location.search);
    //if there is a lever list in the url, use it to populate the levers position. If not, put all the levers to a
    //default scenario
    if (Object.keys(urlData).length > 0) {
      //if the url is "/?levers=" without levers position, then set the levers to the default position (everything to 1)
      if (urlData.levers.length > 0) {
        // urlData = urlData.levers.match(/[0-9]{1,2}/g);
        urlData = urlData.levers.split("");
        fetchedLevers.forEach((e, i) => {
          // e.value = urlData[i];
          e.value = VALUE_CONVERSION_TABLE.indexOf(urlData[i]) + 10;
          e.id = i;
        });
      } else {
        fetchedLevers.forEach((e, i) => {
          e.value = 10;
          e.id = i;
        });
      }
    } else {
      fetchedLevers.forEach((e, i) => {
        e.value = 10;
        e.id = i;
      });
    }
    props.setAllLevers(fetchedLevers);
    setTimeout(() => {
      setIsUpdating(!isUpdating);
    }, 500);
  };

  //Get Pathways
  const getPathwaysFromApi = async () => {
    const response = await fetch(
      "https://becalc.netzero2050.be/api/v1.0/pathways_list",
      {
        method: "GET"
      }
    );
    let pathwaysData = await response.json();
    setPathways(pathwaysData);
  };
  //Make call only if levers doesn't exist
  useEffect(() => {
    if (props.levers.length < 1) {
      getLeversFromApi();
    }
    if (Object.keys(pathways).length < 1) {
      getPathwaysFromApi();
    }
  }, []); // eslint-disable-line

  //Set url when a lever is selected
  useEffect(() => {
    let newUrl = "";
    props.levers.forEach(lever => {
      newUrl = newUrl + VALUE_CONVERSION_TABLE[lever.value - 10];
    });
    const param = `?levers=${newUrl}`;
    props.history.push({
      pathname: "/",
      search: param
    });
    //Update graphs
    setTimeout(() => {
      setIsUpdating(!isUpdating);
    }, 500);
  }, [props.levers]); // eslint-disable-line

  const calcClickHandler = () => {
    setIsUpdating(!isUpdating);
  };

  //Set levers when a Pathway is selected
  const handlePathwaySelector = e => {
    const newLevers = props.levers.map((lever, i) => {
      lever.value = +pathways[e][i].toFixed(1) * 10;
      return lever;
    });
    props.setAllLevers(newLevers);
    //Update graphs
    setTimeout(() => {
      setIsUpdating(!isUpdating);
    }, 500);
  };

  //* –––––––––––––––––––––––
  //*
  //* rendering
  //*
  //* –––––––––––––––––––––––

  return (
    <div className="main">
      <Levers
        pathways={pathways}
        levers={props.levers}
        handlePathwaySelector={handlePathwaySelector}
        setIsUpdating={calcClickHandler}
      />
      <div className="display">
        <MapControl levers={props.levers} isUpdating={isUpdating} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    levers: state.stateLevers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAllLevers: value => dispatch({ type: "SET_ALL_LEVERS", payload: value })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
