import React, { useState, useEffect } from "react";
// import { PATHWAYS } from "../../utils/constants";
import Select from "react-select";
import ModalInfo from "../Modal/ModalInfo";

import "./Pathway.css";
const Pathway = props => {
  const [pathways, setPathways] = useState([]);
  //const [select, setSelect] = useState("");
  const [value, setValue] = useState("Select...");
  const [resetSelect, setResetSelect] = useState(true);
  const [showModal, setShowModal] = useState(false);

  //Hide or show the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  // const pathways = PATHWAYS.map(pathway => ({
  //   value: pathway,
  //   label: pathway
  // }));
  useEffect(() => {
    const pathways = Object.keys(props.pathways).map(pathway => ({
      value: pathway,
      label: pathway
    }));
    if (pathways.length > 0) {
      setPathways(pathways);
    }
  }, [props.pathways]);

  useEffect(() => {
    if (resetSelect) {
      setValue("Select...");
    }
    setResetSelect(true);
  }, [props.levers]); // eslint-disable-line

  function handlePathwayChange(newValue) {
    setResetSelect(false);
    setValue(newValue.value);
    props.handlePathwaySelector(newValue.value);
  }

  return (
    <React.Fragment>
      <ModalInfo show={showModal} modalClosed={toggleModal} />
      {
        <div className="pathway">
          <p className="lead">Choose an example pathway</p>

          <div className="pathway-control">
            <Select
              isSearchable = {false}
              value={value}
              className="pathway-selector"
              onChange={handlePathwayChange}
              options={pathways}
              placeholder={value}
            />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default Pathway;
