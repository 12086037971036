export default async (fetchUrl, metrics, levers) => {
  const bodyMessage = {
    levers: {
      default: levers
    },
    outputs: metrics
  };

  try {
    const data = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bodyMessage)
    });
    let result = await data.json();
    return result;
  } catch (error) {
    console.log("The server is busy: ", error);
    return [];
  }
};
