import React from "react";
import "./Modal.css";
import Chart from "react-apexcharts";

import Backdrop from "./Backdrop/Backdrop";

const Modal = props => {
  let source;
  let assumption;
  let state;

  state = {
    options: {
      chart: {
        type: "line",
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          },
          autoSelected: "zoom"
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 100,
          animateGradually: {
            enabled: false,
            delay: 20
          },
          dynamicAnimation: {
            enabled: true,
            speed: 100
          }
        }
      },
      stroke: {
        curve: "straight",
        width: 3
      },
      fill: {
        type: "solid"
      },
      colors: ["#218C8D", "#6CCECB", "#F9E559", "#EF7126"],
      xaxis: {
        type: "numeric",
        tickAmount: 7,
        labels: {
          offsetY: -3,
          formatter: function(val) {
            return val.toFixed();
          }
        },
        categories: [
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2025,
          2030,
          2035,
          2040,
          2045,
          2050
        ]
      },
      legend: {
        position: "bottom",
        inverseOrder: false,
        showForSingleSeries: true,
        offsetX: 0,
        offsetY: 0,
        horizontalAlign: "center"
      },
      tooltip: {
        enabled: true,
        shared: true,
        inverseOrder: false,
        y: {
          formatter: function(val) {
            if (val > 1000000000) {
              return (
                (val / 1000000000)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bn"
              );
            } else if (val > 1000000) {
              return (val / 1000000).toFixed(2) + " m";
            } else if (val > 1000) {
              return (val / 1000).toFixed(2) + " k";
            } else {
              if (val !== undefined) {
                if (val !== parseInt(val)) val = val.toFixed(2);
              }
              return val;
            }
          }
        }
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            cssClass: "apexcharts-yaxis-label"
          },
          formatter: function(val) {
            if (val > 1000000000) {
              return (
                (val / 1000000000)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bn"
              );
            } else if (val > 1000000) {
              return val / 1000000 + " m";
            } else if (val > 1000) {
              return val / 1000 + " k";
            } else {
              if (val !== undefined) {
                if (val !== parseInt(val)) val = val.toFixed(2);
              }
              return val;
            }
          }
        }
        /*title: {
          text: props.unit,
          rotate: 90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title"
          }
        }*/
      },
      title: {
        text: props.figure_caption + " [" + props.unit + "]",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "16px",
          fontWeight: 800,
          color: "#263238"
        }
      },
      animations: {
        enabled: false
        // easing: "easeinout",
        // speed: 5,
        // animateGradually: {
        //  enabled: false,
        //  delay: 20
        //},
        //dynamicAnimation: {
        //  enabled: false,
        //  speed: 10
        //}
      },
      markers: {
        size: 5,
        colors: undefined,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3
        }
      }
    },
    series: [
      {
        name: "Ambition Level 1",
        data: props.l1_data
      },
      {
        name: "Ambition Level 2",
        data: props.l2_data
      },
      {
        name: "Ambition Level 3",
        data: props.l3_data
      },
      {
        name: "Ambition Level 4",
        data: props.l4_data
      }
    ]
  };

  if (props.sources === "TO DO") {
    source = "-";
  } else {
    source = props.sources;
  }
  if (props.assumptions === "TO DO") {
    assumption = "-";
  } else {
    assumption = props.assumptions;
  }

  let ChartLevers = null;
  if (props.show) {
    //avoid charging all the graphics when modals are not shown
    ChartLevers = (
      <div>
        <Chart
          options={state.options}
          series={state.series}
          type="line"
          height="300"
        />
      </div>
    );
  }

  return (
    <div className="">
      <Backdrop show={props.show} clicked={props.modalClosed} />
      <div
        className="modal-lever"
        style={{
          transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0"
        }}
      >
        <div className="modal-header">
          <h2 className="mb-2">{props.name}</h2>
        </div>
        <div className="modal-content">
          <h3>Lever definition</h3>
          <p className="mb-4">{props.definition}</p>
          <h3>Ambition levels</h3>
          {ChartLevers}
          <ul style={{ margin: "20px" }}>
            <li className="ambition-level">
              <div>Ambition level 1</div>
              <p> {props.l1}</p>
            </li>
            <li className="ambition-level">
              <div>Ambition level 2</div>
              <p> {props.l2}</p>
            </li>
            <li className="ambition-level">
              <div>Ambition level 3</div>
              <p> {props.l3}</p>
            </li>
            <li className="ambition-level">
              <div>Ambition level 4</div>
              <p> {props.l4}</p>
            </li>
          </ul>
          <h3>Sources</h3>
          <p className="mb-4">{source}</p>
          <h3>Assumptions</h3>
          <p className="mb-4">{assumption}</p>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Modal);
