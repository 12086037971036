import React from 'react'
import "./Main.css";

function generalCondition(){
    return (
        <div className="main-toc">
            <div className="display">
                <h1>Conditions of Use</h1>
                <br/>
                <p>This tool has been developed by Climact for the Belgian Federal Public Service (FPS) Public Health. Climact and the FPS Public Health provide no express or implied warranties concerning this tool and its content and, accordingly, accept no liability arising from the use of the tool or its content.</p>
                <p>The underlying model and input data are available under the creative commons license <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">Attribution-NonCommercial-ShareAlike</a>.  You can contact <a href="mailto:info@climact.com">info@climact.com</a> for more detailed info.</p>
                <p>By navigating this site, you accept the <a href="https://www.health.belgium.be/en/privacy">data protection policy </a> of the FPS Public Health.</p>
            </div>
          
        </div>
    )
}

export default generalCondition